[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  --tootip-bg: #434343;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --text-color-fourth: #adaeb4;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue1: #225085;
  --blue2: #507dc9;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  /*---------- app asset colors ----------*/
  --app-asset-bg: #1b1b1b;
  --app-legend-bg: #8c8c8c;
  --app-text-color: #8f8f8f;
  --app-timepicker-bg: #454545;
  /*---------- chart colors ----------*/
  --tooltip-bg: rgba(47, 51, 66, 0.91);
  --statistic-color: #bcbcbc;
  --depth-color: #61688a;
  --depth-tip-color: #cad7e0;
  --depth-bg-color: rgba(0, 0, 0, 0.8);
  --depth-bidsLineColor: #41b37d;
  --depth-asksLineColor: #d74e5a;
  --depth-axisColor: rgba(97, 104, 138, 0.3);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="mode-light"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  --tootip-bg: #434343;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --text-color-fourth: #adaeb4;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue1: #225085;
  --blue2: #507dc9;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  /*---------- app asset colors ----------*/
  --app-asset-bg: #1b1b1b;
  --app-legend-bg: #8c8c8c;
  --app-text-color: #8f8f8f;
  --app-timepicker-bg: #454545;
  /*---------- chart colors ----------*/
  --tooltip-bg: rgba(47, 51, 66, 0.91);
  --statistic-color: #bcbcbc;
  --depth-color: #61688a;
  --depth-tip-color: #cad7e0;
  --depth-bg-color: rgba(0, 0, 0, 0.8);
  --depth-bidsLineColor: #41b37d;
  --depth-asksLineColor: #d74e5a;
  --depth-axisColor: rgba(97, 104, 138, 0.3);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7a52f4;
  --saas-main-color: #4e2c5b;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #bc20fe;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ed3ee;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #a833cc;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #cfbfff;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #4a4c51;
  --login-bg2: #2e1b3f;
  --market-thead-bg: #262628;
  --editor-menu-border-color: #e5e7eb;
  --editor-a-color: #1677ff;
  --editor-a-hover-color: #69b1ff;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
[data-theme="summer-capital-mode"] {
  --main-bg-color: #000;
  --main-color: #d9a527;
  --saas-main-color: #693a0f;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #d9a527;
  --saas-table-btn-bg1: rgba(217, 165, 39, 0.2);
  --saas-table-btn-border2: #eb892e;
  --saas-table-btn-bg2: rgba(235, 137, 46, 0.2);
  --saas-product-label-bg1: #daa729;
  --saas-product-label-bg2: #693a10;
  --saas-investor-label-bg: rgba(217, 165, 39, 0.2);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #ffdd8c;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #ffe7ac;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #6c3500;
  --login-bg2: #201102;
  --market-thead-bg: #262628;
  --editor-menu-border-color: #e5e7eb;
  --editor-a-color: #1677ff;
  --editor-a-hover-color: #69b1ff;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.__3z3sMBJe {
  width: 100%;
  background-color: var(--white);
  color: var(--black);
}
.uPNR_0PS {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uPNR_0PS .T7M3kErc {
  display: flex;
}
.uPNR_0PS .Qx_Xb8Ko {
  align-items: center;
  font-size: 14px;
  color: var(--white);
}
.uPNR_0PS .Qx_Xb8Ko .__8_P84y1T {
  cursor: pointer;
}
.uPNR_0PS .Qx_Xb8Ko .__8_P84y1T .logout-dropdown {
  top: 55px !important;
}
.uPNR_0PS .Qx_Xb8Ko .__8_P84y1T .logout-dropdown .ant-dropdown-menu-item {
  min-width: 112px;
  height: 46px;
  display: flex;
  align-items: center;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.uPNR_0PS .Qx_Xb8Ko .__8_P84y1T .logout-dropdown .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
}
.uPNR_0PS .mdSYxHiR {
  margin-right: 22px;
  cursor: pointer;
}
.__7k0tP5xg {
  margin-top: 23px;
}
.BcstMrF6 {
  margin-top: 17px;
}
.HE_MR6vf {
  margin-left: 34px;
}
.RpoQ5c54 {
  margin-left: 51px;
}
.nEFp0ohr {
  margin-bottom: 19px;
}
.MIQepVud {
  margin-bottom: 36px;
}
.jCZ281if {
  font-family: var(--CommonBold);
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 64px;
  text-align: center;
  color: var(--black);
}
.eMiSZgoM {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: var(--black);
}
.VUprvvwa {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: var(--white);
}
.K4IxUlI3 {
  font-family: var(--CommonBold);
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  color: var(--black);
  margin-left: 24px;
}
.__544Db4go {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: var(--black);
}
.sYEg8g3A {
  font-family: var(--CommonBold);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  color: var(--black);
}
.FHspiXXd {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--black);
}
.t5bmp9UU {
  width: 100%;
  min-height: 695px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/images/download_web_bg@2x.webp");
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.t5bmp9UU .uDhyEgOe {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.t5bmp9UU .WGwIhYF4 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ShpH_H_j {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}
.ShpH_H_j > div {
  width: 100%;
}
.ShpH_H_j .hjGOdaqS {
  width: 100%;
  background-color: var(--main-bg-color);
  display: flex;
  align-items: center;
  padding: 17px 67px;
  justify-content: center;
}
.ShpH_H_j .hjGOdaqS .__7nH_a3pv {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ShpH_H_j .hjGOdaqS .__53D9z5Kg {
  flex: 0;
}
.ShpH_H_j .hjGOdaqS .__53D9z5Kg .d_7JObHl {
  font-size: 28px;
  color: var(--white);
}
.u0tgnqCI {
  flex: 1;
}
.__0FVYyess {
  padding: 94px 0;
}
.__0FVYyess .fFkQUbtg {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 0 94px;
}
.__0FVYyess .qv5amYCk {
  padding: 0 80px 0 107px;
}
.__0FVYyess .IoIjiAZe {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.__0FVYyess .IoIjiAZe .sBom1N6f {
  display: flex;
  flex-direction: row;
  padding: 70px 0 81px 116px;
}
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3),
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) {
  background: var(--module-bg);
}
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) .sYEg8g3A,
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) .sYEg8g3A,
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) .FHspiXXd,
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) .FHspiXXd {
  color: var(--white);
}
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(even) {
  padding-right: 94px;
}
.__0FVYyess .IoIjiAZe .sBom1N6f .gewYvRXv {
  display: flex;
  align-items: flex-start;
}
.__0FVYyess .IoIjiAZe .sBom1N6f .d2PwE2YV {
  margin-top: 12px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--main-color);
  margin-right: 14px;
}
.__0FVYyess .IoIjiAZe .sBom1N6f .K0J3Eaw_ {
  flex: 0;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.__0FVYyess .IoIjiAZe .sBom1N6f .__4xv2iWiB {
  flex: 1;
  display: flex;
}
@media (max-width: 1200px) {
  .ant-layout-header {
    min-width: 0;
  }
  .jCZ281if {
    font-size: 24px;
    line-height: 31px;
  }
  .eMiSZgoM {
    font-size: 16px;
    line-height: 21px;
  }
  .VUprvvwa {
    font-size: 14px;
    line-height: 18px;
  }
  .K4IxUlI3 {
    font-size: 18px;
    line-height: 20px;
    margin-left: 8px;
  }
  .__544Db4go {
    font-size: 14px;
    line-height: 21px;
  }
  .sYEg8g3A {
    font-size: 16px;
    line-height: 18px;
  }
  .FHspiXXd {
    font-size: 14px;
    line-height: 21px;
  }
  .HE_MR6vf {
    margin-left: 12px;
  }
  .RpoQ5c54 {
    margin-left: 48px;
  }
  .MIQepVud {
    margin-bottom: 10px;
  }
  .t5bmp9UU {
    min-height: 448px;
    background-image: url("/images/download_app_bg@3x.webp");
  }
  .t5bmp9UU .uDhyEgOe {
    margin-top: 38px;
    padding: 0 37px;
  }
  .t5bmp9UU .WGwIhYF4 {
    margin: 49px 0 21px 0;
  }
  .ShpH_H_j .hjGOdaqS {
    padding: 10px 20px;
  }
  .ShpH_H_j .hjGOdaqS .__53D9z5Kg .d_7JObHl {
    font-size: 16px;
  }
  .__0FVYyess {
    padding: 24px 0 0 0;
  }
  .__0FVYyess .fFkQUbtg {
    margin-bottom: 18px;
    padding: 0 24px;
  }
  .__0FVYyess .qv5amYCk {
    padding: 0 24px;
  }
  .__0FVYyess .IoIjiAZe {
    margin-top: 29px;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f {
    flex-direction: column;
    padding: 24px;
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 1) {
    background: var(--module-bg);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 1) .sYEg8g3A,
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 1) .FHspiXXd {
    color: var(--white);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) {
    background: var(--main-color);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) .d2PwE2YV {
    background-color: var(--white);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) .sYEg8g3A,
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) .FHspiXXd {
    color: var(--white);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 2),
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) {
    background: var(--white);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 2) .sYEg8g3A,
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) .sYEg8g3A,
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 2) .FHspiXXd,
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) .FHspiXXd {
    color: var(--black);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(even) {
    padding-right: 24px;
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f .d2PwE2YV {
    margin-top: 8px;
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f .K0J3Eaw_ {
    min-width: 0;
    margin-top: 26px;
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f .K0J3Eaw_ img {
    width: 168px !important;
    height: 343px !important;
    min-width: 0 !important;
    min-height: 0 !important;
  }
}

